import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import { Embellishment22 } from "../components/Embellishments"

import "../css/faq.css"

function Section1({ title, body }) {
  return (
    <div className="section">
      <div className="padding---subpage-hero-alt">
        <div className="subpage-hero-interior-padding-alt background---black">
          <h6 className="h6 text---white sans spacing---md">{title}</h6>
          <h1 className="h1 text---white">{body}</h1>
          <Embellishment22 />
        </div>
      </div>
    </div>
  )
}

function Section2({ title, list }) {
  return (
    <div className="section">
      <div className="xl---padding-12---2-col lg---padding-12---1-col">
        <div className="xl---padding-8---1-col-right lg---padding-10---1-col-right">
          <h6 className="h6 sans-bold spacing---lg">{title}</h6>
          {list.map((item, index) => (
            <div key={`item-${index}`} className="spacing---xxl">
              <h3 className="h3 spacing---md">{item.question}</h3>
              <p dangerouslySetInnerHTML={{ __html: item.answer }} />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function FAQPage({ data }) {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <Helmet>
        <title>{post.frontmatter.title}</title>
      </Helmet>
      <Section1 {...post.frontmatter.hero} />
      <Section2 {...post.frontmatter.questions} />
    </Layout>
  )
}

FAQPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default FAQPage

export const query = graphql`
  query FAQPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        title
        hero {
          title
          body
        }
        questions {
          title
          list {
            question
            answer
          }
        }
      }
    }
  }
`
